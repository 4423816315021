import { use100vh } from 'react-div-100vh'
import { useRouter } from 'next/navigation'
import { HEADER_HEIGHT, URL_PATH } from '@/consts'
import { useModal } from '@/hooks/useModal'
import Button from '@/v1/Button'
import MaterialSymbol from '@/v1/MaterialSymbol'
import { Divider } from '../Divider'
import UserFeedback from '../UserFeedback'
import { FeedbackContentType } from '../UserFeedback/types'

export const BUTTON_TEXT = '내가 쓴 질문 보기'

interface Props {
  type?: 'boardCreate' | 'boardUpdate'
  mainContent: string
  subContent: string
  id: number
}

export default function Complete({ type, mainContent, subContent, id }: Props) {
  const { fullScreenModal } = useModal()
  const { push } = useRouter()
  const getHeight = use100vh()
  const height = getHeight
    ? getHeight - HEADER_HEIGHT - 50
    : `calc(100vh - ${HEADER_HEIGHT}px - 50px)` // 상단 헤더와 하단 버튼 영역

  const handleButtonClick = () => {
    push(`${URL_PATH.Boards}${id}/`)
    fullScreenModal.hide()
  }

  return (
    <>
      <div
        className="h-full flex flex-col items-center justify-between px-md pb-md"
        style={{ height }}
      >
        <div className="flex flex-1 items-center text-gray-100">
          <div className="flex flex-col items-center">
            <MaterialSymbol
              name="check_circle_fill"
              size={48}
              className="fill-aqua-500"
            />
            <div className="text-center mt-4">
              <p className="prose-p1 mb-3">
                <b>{mainContent}</b>
              </p>
              <p className="prose-p3 font-medium whitespace-pre-line mb-md">
                {subContent}
              </p>

              <Button
                type="button"
                variant="filled"
                size="medium"
                color="primary"
                onClick={handleButtonClick}
                data-ga="board_complete_view"
                block={false}
                className="!w-[180px]"
              >
                {BUTTON_TEXT}
              </Button>
            </div>
            {type === 'boardCreate' && (
              <>
                <Divider className="border-grey-100 border-[1px] w-full mt-8" />
                <UserFeedback
                  contentId={id.toString()}
                  type={FeedbackContentType.qna_ask}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
